import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    h1: {
      fontFamily: `"Zilla Slab", serif`,
    },
    a: {
      color: '#13007C',
    },
    blockquote: {
      borderColor: '#13007C',
    },
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

Wordpress2016.bodyFontFamily = ['Quicksand', 'sans-serif'];
Wordpress2016.headerFontFamily = ['Zilla Slab', 'serif'];

Wordpress2016.googleFonts = [
  {
    name: 'Abril Fatface',
    styles: [],
  },
  {
    name: 'Quicksand',
    styles: ['Medium'],
  },
  {
    name: 'Zilla Slab',
    styles: ['Medium'],
  },
];

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
